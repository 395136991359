import React, { useState, useEffect } from "react";
import "./PatientDashboard.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { getBearer, getAdherence } from "../../ApiCalls";
import { useSearchParams, useNavigate } from "react-router-dom";
import List from "../List/List";
import { Typography } from "@mui/material";
import moment from "moment/moment";
function PatientDashboard() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [adherence, setAdherence] = useState("");
  const [token, setToken] = useState("");

  let nfcuid = searchParams.get("uid");

  let payload = {
    nfcuid: nfcuid,
    token: token,
  };

  useEffect(() => {
    getBearer().then((res) => {
      setToken(res);
    });

    if (token) {
      getAdherence(payload).then((res) => {
        setAdherence(res);
        console.log(res);
      });
    }
  }, [token]);

  return (
    <div className="PatientDashboard">
      <Header></Header>
      <List token={token} adherence={adherence} />

      <Footer></Footer>
    </div>
  );
}

export default PatientDashboard;
