import { ReactComponent as NomiFooterSvg } from "../../assets/NomiFooter.svg";
import "./Footer.css";
function Footer() {
  return (
    <div className="footer">
      <NomiFooterSvg></NomiFooterSvg>
    </div>
  );
}
export default Footer;
