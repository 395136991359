import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "./List.css";
import ListItem from "../ListItem/ListItem";
import { Typography, Divider } from "@mui/material";
import moment from "moment/moment";
function List(props) {
  return (
    <div className="List">
      <Box
        sx={{
          width: "100%",
          height: "10%",
          backgroundColor: "#4593cf",
          "&:hover": {
            backgroundColor: "#4593cf",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            paddingTop: "2%",
          }}
          variant="h6"
          component="h6"
        >
          Your Schedule for
        </Typography>
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            paddingBottom: "2%",
          }}
          variant="h6"
          component="h6"
        >
          {moment().format("dddd MMMM Do, YYYY")}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        sx={{ paddingTop: "5%", color: "#4593cf", textAlign: "center" }}
      >
        Active Doses
        <Divider color="#4593cf" sx={{ height: 2, width: "100%" }} />
      </Typography>
      {props.adherence ? (
        <div>
          {(() => {
            const components = [];
            for (let i = 0; i < props.adherence.periods.length; i++) {
              if (
                (props.adherence.periods[i].period_date =
                  moment().format("YYYY-MM-DD"))
              ) {
                components.push(
                  <ListItem index={i} key={i} adherence={props.adherence} />
                );
              } else continue;
            }
            return components;
          })()}
        </div>
      ) : (
        <span
          style={{
            color: "red",
            fontSize: "16px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Loading data...
        </span>
      )}

      <Typography
        variant="h5"
        sx={{ paddingTop: "5%", color: "#4593cf", textAlign: "center" }}
      >
        Missed Doses
        <Divider color="#4593cf" sx={{ height: 2, width: "100%" }} />
      </Typography>
      {props.adherence ? (
        <div>
          {(() => {
            const components = [];
            for (let i = 0; i < props.adherence.periods.length; i++) {
              if (
                props.adherence.periods[i].period_date <
                moment().format("YYYY-MM-DD")
              ) {
                components.push(
                  <ListItem index={i} key={i} adherence={props.adherence} />
                );
              } else continue;
            }
            return components;
          })()}
        </div>
      ) : (
        <span
          style={{
            color: "red",
            fontSize: "16px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Loading data...
        </span>
      )}
    </div>
  );
}
export default List;
